import {
  Divider,
  Link,
  Paper,
  styled,
  SxProps,
  Theme,
  Typography
} from '@mui/material'
import React from 'react'
import {useFormContext} from 'react-hook-form'
import {Trans, useTranslation} from 'react-i18next'
import {
  GetCurrentCartQuery,
  LeadField,
  LeadInputStatus,
  LeadOptionFieldsFragment
} from '../../__generated__/schema'
import {
  BasicCheckboxDescription,
  UncontrolledFormCheckbox
} from '../components/molecules/UncontrolledFormCheckbox'
import {useTranslatePrice} from '../hooks/translateCurrency'
import {getHelperNote} from './ContactInformationContent'
import {CartPageFormField, ICartPageForm} from './types'

const SpaceBetweenRow = styled('div')`
  display: flex;
  justify-content: space-between;
  min-height: 36px;
  align-items: center;
`

interface ISummaryPaperProps {
  sx?: SxProps<Theme>
  cart: GetCurrentCartQuery['cart']
  leadOptions: LeadOptionFieldsFragment[]
  isCustomerSignedIn: boolean
}

export const SummaryPaper: React.FC<ISummaryPaperProps> = ({
  cart,
  sx,
  leadOptions,
  isCustomerSignedIn
}: ISummaryPaperProps) => {
  const translatePrice = useTranslatePrice(cart.client.currency)
  const {t} = useTranslation()
  const {errors, control} = useFormContext<ICartPageForm>()
  const TOSRequiredText = t(
    'It is mandatory to check this box before proceeding.'
  )
  const visibleLeadOptions = leadOptions.filter((lo) =>
    [
      LeadInputStatus.Recommended,
      LeadInputStatus.Required,
      LeadInputStatus.Optional
    ].includes(lo.inputStatus)
  )
  const privacyPolicyOptions = visibleLeadOptions.find(
    (o) => o.field === LeadField.IsPrivacyPolicyConsentGranted
  )
  const areFeesVisible = false
  return (
    <Paper sx={{borderRadius: 4, ...sx}} variant="outlined">
      <SpaceBetweenRow>
        <Typography variant="subtitle2">{t('Subtotal')}</Typography>
        <Typography variant="subtitle2">
          {translatePrice(cart.price)}
        </Typography>
      </SpaceBetweenRow>
      {areFeesVisible && (
        <>
          <SpaceBetweenRow>
            <Typography variant="body2">{t('Payment fee')}</Typography>
            <Typography variant="body2">{translatePrice(0)}</Typography>
          </SpaceBetweenRow>
          <SpaceBetweenRow>
            <Typography variant="body2">{t('Delivery fee')}</Typography>
            <Typography variant="body2">{translatePrice(0)}</Typography>
          </SpaceBetweenRow>
          <SpaceBetweenRow>
            <Typography variant="body2">{t('Processing fee')}</Typography>
            <Typography variant="body2">{translatePrice(0)}</Typography>
          </SpaceBetweenRow>
        </>
      )}
      <Divider />
      <SpaceBetweenRow sx={{height: 48}}>
        <Typography variant="subtitle1">{t('Total')}</Typography>
        <Typography variant="subtitle1">
          {translatePrice(cart.price)}
        </Typography>
      </SpaceBetweenRow>
      <Divider />
      <UncontrolledFormCheckbox<ICartPageForm>
        errors={errors}
        control={control}
        name={CartPageFormField.DoAgreeWithTOS}
        validationOptions={{
          required: TOSRequiredText
        }}
        sx={{width: '100%'}}
        label={t('I agree with the Terms of Service')}
        checkboxDescription={
          <BasicCheckboxDescription>
            <Trans i18nKey="I agree with TOS">
              I agree with the
              <Link
                color="inherit"
                href={cart.client.termsOfServiceUrl || '#'}
                target="_blank"
                rel="noopener"
              >
                <>
                  General Terms and Conditions of the{' '}
                  {{clientName: cart.client.name}}
                </>
              </Link>
            </Trans>
            {cart.client.marketingInformationUrl && (
              <>
                <br />
                <Trans i18nKey="I agree with privacy policy">
                  I agree with the{' '}
                  <Link
                    color="inherit"
                    href={cart.client.marketingInformationUrl}
                    target="_blank"
                    rel="noopener"
                  >
                    privacy policy
                  </Link>
                  .
                </Trans>
              </>
            )}
            {cart.client.appProviderB2CTermsOfServiceUrl && (
              <>
                <br />
                <Trans i18nKey="I agree with B2CTOS">
                  And I agree with the
                  <Link
                    color="inherit"
                    href={cart.client.appProviderB2CTermsOfServiceUrl}
                    target="_blank"
                    rel="noopener"
                  >
                    {' '}
                    General Terms and Conditions of the Entradio operator
                  </Link>
                  .
                </Trans>
              </>
            )}
          </BasicCheckboxDescription>
        }
        helperText={TOSRequiredText}
      />
      {privacyPolicyOptions && !isCustomerSignedIn && (
        <UncontrolledFormCheckbox<ICartPageForm>
          errors={errors}
          control={control}
          name={LeadField.IsPrivacyPolicyConsentGranted}
          label={t('I would like to receive the latest news')}
          helperText={getHelperNote(t, privacyPolicyOptions)}
          validationOptions={{
            required:
              privacyPolicyOptions.inputStatus === LeadInputStatus.Required
          }}
          sx={{width: '100%'}}
          checkboxDescription={
            <BasicCheckboxDescription>
              <Trans i18nKey="I want to be kept informed">
                {
                  "I want to be kept informed about Attendio and Promoter's news. "
                }
                <Link
                  color="inherit"
                  href={cart.client.marketingInformationUrl || '#'}
                  target="_blank"
                  rel="noopener"
                >
                  What does that mean
                </Link>
                ?
              </Trans>
            </BasicCheckboxDescription>
          }
        />
      )}
    </Paper>
  )
}
